export function useEvolvRegisterTest() {
  const isEvolvRegisterPetsTest = ref(false);
  const isRegisterTest = computed(
    () => Boolean(window?.evolvRegisterTest) || isEvolvRegisterPetsTest.value
  );
  const handleEvolvEvent = (event: CustomEvent) => {
    const { detail } = event;

    if (detail === "pets") {
      isEvolvRegisterPetsTest.value = true;
      window.evolvRegisterTest = true;
    }
  };
  onMounted(() => {
    window.addEventListener(
      "evolv:RegisterTest",
      handleEvolvEvent as EventListener
    );
  });
  onUnmounted(() => {
    window.removeEventListener(
      "evolv:RegisterTest",
      handleEvolvEvent as EventListener
    );
  });
  return {
    isEvolvRegisterPetsTest,
    isRegisterTest,
  };
}
